(function($) {
	window.scrollTo = function(top, force) {
		if (force) {
			$('html,body').scrollTop(top);
		} else {
			$('html,body').animate({
				scrollTop: top
			}, 900);
		}
	}

	window.hideShow = function($el, visible, force) {
		if (visible) {
			if (force) {
				$el.show();
			} else {
				$el.slideDown();
			}
		} else {
			if (force) {
				$el.hide();
			} else {
				$el.slideUp();
			}
		}
	}

	window.classSwitch = function($el, val, on, off) {
		if (val) {
			$el.addClass(on);

			if (off) {
				$el.removeClass(off);
			}
		} else {
			$el.removeClass(on);

			if (off) {
				$el.addClass(off);
			}
		}
	}

	window.classToggler = function($el, on, off) {
		classSwitch($el, !$el.hasClass(on), on, off);
	}

	window.flashItem = function($el, css) {
		if ($el.hasClass(css)) {
			return;
		}

		$el.addClass(css);

		setTimeout(function() {
			$el.removeClass(css);
		}, 500);
	}
})(jQuery);
