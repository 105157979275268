"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./utils.js");
var Site = /** @class */ (function () {
    function Site() {
    }
    Site.onCartForm = function ($uid) {
        var $trs = $('tr', $uid);
        function getTrByUid(uid) {
            return $trs.filter('[data-uid="' + uid + '"]');
        }
        function update() {
            $.post($uid.data('url'), function (data) {
                $uid.replaceWith(data);
            });
        }
        function inputUpdate($el, id, key) {
            $el.change(function (e) {
                $uid.addClass('vcart__products--loading');
                cart.set(id, key, $el.val(), function (r) {
                    // $el.val(r.value);
                    update();
                    cart.update();
                }, function (r) {
                    $uid.removeClass('vcart__products--loading');
                    alert(r.message);
                });
            });
        }
        $('.cart-products tr', $uid).each(function () {
            var $tr = $(this);
            var id = $tr.data('id');
            $('.js-remove', $tr).click(function (e) {
                e.preventDefault();
                cart.remove(id, function () {
                    update();
                });
            });
            var $quantity = $('.js-quantity', $tr);
            inputUpdate($quantity, id, 'quantity');
            // $('.js-comment', $tr).change(function(e) {
            // 	cart.set(id, 'comment', $(this).val());
            // });
            $quantity.qSpinner({
                value: $quantity.val(),
                max: $quantity.data('max'),
                onLazySpin: function (e, val) {
                    $quantity.change();
                }
            });
        });
        $('.js-selectall', $uid).click(function (e) {
            e.preventDefault();
            $('.checkbox', $uid).addClass('checkbox--checked');
        });
        $('.js-removeall', $uid).click(function (e) {
            e.preventDefault();
            var ids = [];
            $('.checkbox--checked', $uid).closest('tr').each(function () {
                ids.push($(this).data('id'));
            });
            cart.remove(ids, function () {
                update();
            });
        });
        $('.vcart__message :input', $uid).each(function () {
            inputUpdate($(this), null, $(this).prop('name'));
        });
        $('.js-submit', $uid).click(function (e) {
            e.preventDefault();
            $.post('/' + lang + '/cartapi/submit', function (r) {
                if (r.success == 'ok') {
                    $('.js-submit-error').text('');
                    $uid.replaceWith(r.message);
                }
                else {
                    $trs.removeClass('cart-products__item--error');
                    if (r.field) {
                        var $tr = getTrByUid(r.field);
                        if ($tr.length) {
                            $tr.addClass('cart-products__item--error');
                        }
                    }
                    $('.js-submit-error').text(r.message);
                }
            });
        });
        window.updateElements($uid);
    };
    Site.search = function ($tr, query) {
        var $tds = $('td', $tr);
        var found = false;
        $tds.each(function (ix, el) {
            var $td = $(el);
            var text = $td.text().toLowerCase();
            var pos = text.indexOf(query);
            if (pos != -1) {
                found = true;
                return true;
            }
        });
        return found;
    };
    Site.onMainSlider = function ($uid) {
        $uid.slick({
            arrows: true,
            dots: false,
            infinite: true,
            autoplay: 5000,
            prevArrow: this.SLICK_PREV,
            nextArrow: this.SLICK_NEXT,
            slidesToShow: 1,
        });
    };
    Site.onSideWidget = function ($uid) {
        $uid.on('mouseover', function () { return $uid.addClass('sidewidget--active'); });
        $uid.on('mouseleave', function () { return $uid.removeClass('sidewidget--active'); });
    };
    Site.onTraderPage = function () {
        $(function () {
            var $query = $('.vtrader__query input');
            var $items = $('.vtrader__table tbody tr');
            function update() {
                var q = $query.val().toLowerCase();
                $items.hide();
                if (q.length > 0) {
                    $items.each(function (ix, el) {
                        var $tr = $(el);
                        if (Site.search($tr, q)) {
                            $tr.show();
                        }
                    });
                }
                else {
                    $items.show();
                }
            }
            var timer = null;
            $query.on('keyup', function (e) {
                if (timer != null) {
                    clearTimeout(timer);
                    timer = null;
                }
                timer = setTimeout(function () {
                    timer = null;
                    update();
                }, 250);
            });
        });
    };
    Site.init = function () {
        window.Site = Site;
    };
    Site.SLICK_PREV = '<button type="button" class="slick-prev slick-navi-1"><i class="fa fa-arrow-left"></i></button>';
    Site.SLICK_NEXT = '<button type="button" class="slick-next slick-navi-1"><i class="fa fa-arrow-right"></i></button>';
    return Site;
}());
(function ($) {
    window.lang = $('html').data('lang');
    $.fn.qSpinner = function (options) {
        var $this = $(this);
        var options = $.extend({
            min: 1,
            value: 1,
            onSpin: null,
            onLazySpin: null
        }, options);
        function spinUpdate(e, ui) {
            var val = ui != null && ui.value != null ? ui.value : $this.val();
            $this.trigger('spin', val);
        }
        var timer = null;
        $this.spinner({
            min: options.min,
            max: options.max,
            spin: function (e, ui) {
                spinUpdate(e, ui);
                if (options.onLazySpin != null) {
                    if (timer != null)
                        window.clearTimeout(timer);
                    timer = window.setTimeout(function () {
                        $this.trigger('lazyspin', ui.value);
                    }, 500);
                }
            },
            change: spinUpdate
        });
        if (options.onSpin != null)
            $this.on('spin', options.onSpin);
        if (options.onLazySpin != null)
            $this.on('lazyspin', options.onLazySpin);
        $this.spinner('value', options.value);
        $this.siblings('.ui-spinner-up').html('');
        $this.siblings('.ui-spinner-down').html('');
        spinUpdate();
        return $this;
    };
    function updateFloat() {
        var $header = $('.header');
        var top = $(window).scrollTop();
        var wh = $(window).height();
        window.classSwitch($('body'), top > 0, 'float', 'nofloat');
        var hcy = $header.height();
        $('body').css('padding-top', hcy);
    }
    window.cart = new function () {
        var This = this;
        this.update = function (success) {
            $.get('/' + window.lang + '/cartapi/cartbox', function (data) {
                $('.cartbox').replaceWith(data);
                window.updateElements($('.cartbox'));
                setTimeout(function () {
                    window.flashItem($('.cartbox'), 'cartbox--flash');
                    if (success) {
                        success();
                    }
                }, 5);
            });
        };
        this.add = function (id, quantity, success) {
            $('body').trigger('cartupdate--start');
            $.post('/' + window.lang + '/cartapi/add', { id: id, quantity: quantity }, function () {
                $('body').trigger('cartupdate');
                This.update(success);
            });
        };
        this.remove = function (id, success) {
            $('body').trigger('cartupdate--start');
            $.post('/' + window.lang + '/cartapi/remove', { id: id }, function () {
                $('body').trigger('cartupdate');
                This.update(success);
            });
        };
        this.set = function (id, key, value, success, error) {
            var data = {
                key: key,
                value: value
            };
            if (id !== null) {
                data.id = id;
            }
            $.post('/' + window.lang + '/cartapi/set', data, function (r) {
                if (r.success == 'ok') {
                    if (success) {
                        success(r);
                    }
                }
                else {
                    if (error) {
                        error(r);
                    }
                }
            });
        };
    };
    function initPobox() {
        var $pobox = null;
        function poboxClose() {
            if ($pobox != null) {
                $pobox.fadeOut(200, function () {
                    $('body').removeClass('body--lock');
                    $pobox.remove();
                    $pobox = null;
                });
            }
        }
        window.openInfo = function (url) {
            var $box = $('<div class="pobox"> \
				<div class="pobox__overlay"> \
				<div class="pobox__inner"> \
					<a class="pobox__close"></a> \
					<div class="pobox__body"></div> \
				</div> \
			</div>');
            $box.addClass('pobox--loading');
            $box.hide();
            $('body').append($box);
            $(document).click(function (e) {
                var $target = $(e.target);
                if ($target.is($('.pobox__overlay, .pobox__close'))) {
                    poboxClose();
                }
            });
            $('body').addClass('body--lock');
            $box.fadeIn();
            $.get(url, function (html) {
                var $html = $(html);
                $('.pobox__body', $box).append($html);
                $box.removeClass('pobox--loading');
            });
            $pobox = $box;
        };
        window.closeAllInfo = function () {
            poboxClose();
        };
    }
    initPobox();
    window.updateElements = function ($parent) {
        $('.lightbox, .fancybox, a[rel="lightbox"]', $parent).fancybox({
            titlePosition: 'over'
        });
        $('.svg-inject', $parent).svgInject();
        $('.js-gotop', $parent).click(function (e) {
            e.preventDefault();
            scrollTo(0, null);
        });
        $('.js-godown', $parent).click(function (e) {
            e.preventDefault();
            var $this = $(this);
            var $header = $('.header');
            if ($('body').scrollTop() == 0) {
                $header.addClass('header--noanim');
                window.scrollTo(1, true);
                updateFloat();
                $header.removeClass('header--noanim');
            }
            window.setTimeout(function () {
                var name = $this.data('name');
                ;
                var $dst = $('[name="' + name + '"]');
                var hh = $header.height();
                ;
                var top = $dst.offset().top;
                scrollTo(top - hh, null);
            }, 1);
        });
        $('.addtocart', $parent).each(function () {
            var $addtocart = $(this);
            var $quantity = $('.js-quantity', $addtocart);
            $quantity.qSpinner({
                value: $quantity.val(),
                max: $quantity.data('max'),
            });
            $('.js-addtocart', $addtocart).click(function (e) {
                e.preventDefault();
                var $addtocart = $(this).closest('.addtocart');
                var id = $(this).data('id');
                if ($quantity.length) {
                    window.flashItem($addtocart, 'addtocart--added');
                    var quantity = $quantity.val();
                    window.cart.add(id, quantity);
                    if ($addtocart.closest('.addtocartform').length > 0) {
                        window.closeAllInfo();
                    }
                }
                else {
                    var url = '/?view=cart&action=addform&id=' + id;
                    window.openInfo(url);
                }
            });
        });
        $('.checkbox', $parent).click(function () {
            var $cb = $(this);
            window.classToggler($cb, 'checkbox--checked');
        });
    };
    $(document).ready(function () {
        function initHtml() {
            var $mainmenu = $('#mainmenu');
            $('.menubutton', $mainmenu).click(function (e) {
                e.preventDefault();
                window.classToggler($mainmenu, 'mainmenu--opened');
            });
            var $categories = $('.categorieslist');
            $('.categorieslist__item', $categories).each(function () {
                var $item = $(this);
                var $list = $('> .categorieslist__list', $item);
                var $children = $('> .categorieslist__item', $list);
                if ($item.hasClass('categorieslist__item--selected')) {
                    $item.parentsUntil($categories, '.categorieslist__item').addClass('categorieslist__item--focused');
                }
                if ($children.length > 0) {
                    $item.addClass('categorieslist__item--children');
                    $('> .categorieslist__item-head > .categorieslist__item-expander', $item).click(function (e) {
                        e.preventDefault();
                        var opened = !$item.hasClass('categorieslist__item--opened');
                        $item.toggleClass('categorieslist__item--opened', opened);
                        if (opened) {
                            $list.slideDown();
                        }
                        else {
                            $list.slideUp();
                        }
                    });
                }
            });
            var $tohide = $('.categorieslist__item--children:not(.categorieslist__item--focused,.categorieslist__item--selected)', $categories);
            $('.categorieslist__list', $tohide).hide();
            $('.categorieslist__item--children.categorieslist__item--focused, .categorieslist__item--children.categorieslist__item--selected').addClass('categorieslist__item--opened');
            $categories.addClass('categorieslist--inited');
            $('.headernavi').smartHover(function () {
                $('.headernavi').addClass('headernavi--hover');
                $('body').addClass('body--navihover');
            }, function () {
                $('.headernavi').removeClass('headernavi--hover');
                $('body').removeClass('body--navihover');
            }, 200);
            $('.headeruser').smartHover(function () {
                $('.headeruser').addClass('headeruser--hover');
                $('body').addClass('body--userhover');
            }, function () {
                $('.headeruser').removeClass('headeruser--hover');
                $('body').removeClass('body--userhover');
            }, 200);
            $('.headercart').smartHover(function () {
                $('.headercart').addClass('headercart--hover');
                $('body').addClass('body--carthover');
            }, function () {
                $('.headercart').removeClass('headercart--hover');
                $('body').removeClass('body--carthover');
            }, 200);
            window.updateElements();
        }
        function initRwd() {
            var $header = $('.header');
            $header.addClass('header--noanim');
            $(window).resize(updateFloat);
            $(window).scroll(updateFloat);
            updateFloat();
            $header.removeClass('header--noanim');
        }
        initHtml();
        initRwd();
    });
})(jQuery);
Site.init();
